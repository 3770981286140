import { useCustomMuiTheme } from '~/src/hooks/useCustomMuiTheme'
import getCategoryListTemplateService
  from '~/src/components/categories/category-list/styles/GetCategoryListTemplateService'
import StylesCategoryListServiceInterface
  from '~/src/components/categories/category-list/styles/StylesCategoryListServiceInterface'
import useGetPageStyle from '~/src/hooks/layout/useGetPageStyle'

const useGetCategoryListTemplate = (): StylesCategoryListServiceInterface => {
  const muiTheme = useCustomMuiTheme()
  const pageStyle = useGetPageStyle('categories')

  return getCategoryListTemplateService(pageStyle, muiTheme)
}

export default useGetCategoryListTemplate
