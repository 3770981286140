import { Category } from '@eo-storefronts/eo-core'
import CategoryList from '~/src/components/categories/category-list'
import { useEoValue } from '~/src/hooks/useEoState'
import { TOP_CATEGORIES_SELECTOR } from '~/src/stores/categories'
import ProductsSpotlighted from '~/src/components/products/products-spotlighted'
import { Box } from '@mui/material'
import { useCustomMuiTheme } from '~/src/hooks/useCustomMuiTheme'

const CategoriesContainer = () => {
  const topCategories = useEoValue<Category[]>(TOP_CATEGORIES_SELECTOR)
  const muiTheme = useCustomMuiTheme()

  return (
    <Box sx={{
      pb: 6,
      [muiTheme.breakpoints.down('md')]: {
        pb: 2
      }
    }}>
      <ProductsSpotlighted />
      <CategoryList categories={topCategories} />
    </Box>

  )
}

export default CategoriesContainer
