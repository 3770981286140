import Title from '~/src/components/mui-wrappers/Title'
import useGetProductsSpotlightedTemplate
  from '~/src/components/products/products-spotlighted/styles/useGetProductsSpotlightedTemplate'
import { useEoValue } from '~/src/hooks/useEoState'
import { PRODUCTS_SPOTLIGHTED_SELECTOR } from '~/src/stores/product'
import ProductListItem from '~/src/components/products/product-list-item'
import { useTranslations } from '~/src/hooks/useTranslations'
import { FIRM_THEME_SELECTOR } from '~/src/stores/firm'
import HtmlFireIcon from '~/src/components/icons/html-fire'
import ScrollableListItem from '~/src/components/mui-wrappers/scrollable-list/scrollable-list-item'
import ScrollableList from '~/src/components/mui-wrappers/scrollable-list'
import { Product } from '@eo-storefronts/eo-core'

const ProductsSpotlighted = () => {
  const styles = useGetProductsSpotlightedTemplate()
  const { t } = useTranslations()
  const products = useEoValue(PRODUCTS_SPOTLIGHTED_SELECTOR)
  const firmTheme = useEoValue(FIRM_THEME_SELECTOR)

  if (!products || !products?.length) {
    return null
  }

  return (
    <>
      <Title
        variant='h5'
        className='eo-ellipsis'
        sx={styles.getTitleSx()}
      >
        <HtmlFireIcon /> {t('categories.spotlighted_products')}
      </Title>
      <ScrollableList
        draggable
        displayNavButtons
        className='eo-hide-scrollbar'
        orientation='horizontal'
        displayIndicator
        IndicatorProps={{
          indicatorPosition: 'inner',
          indicatorButton: false,
          sx: {
            border: 'none',
            backgroundColor: 'primary.main',
            color: 'primary.contrastText',
            top: 'calc(50% - 1.25rem)'
          }
        }}
        sx={styles.getScrollableListSxProps()}
      >
        {products.map((product: Product, index: number) => (
          <ScrollableListItem
            key={product.id}
            index={index}
            sx={styles.getScrollableItemSxProps()}
          >
            <ProductListItem
              product={product}
              containerTemplate={firmTheme?.components?.spotlighted_products_list}
            />
          </ScrollableListItem>
        ))}
      </ScrollableList>
    </>
  )
}

export default ProductsSpotlighted
