import StylesCategoriesServiceInterface from '~/src/pages/categories/styles/StylesCategoriesServiceInterface'
import StylesCategoriesServiceStyleOne from '~/src/pages/categories/styles/StylesCategoriesServiceStyleOne'
import StylesCategoriesServiceStyleTwo from '~/src/pages/categories/styles/StylesCategoriesServiceStyleTwo'
import { CustomMuiTheme } from '~/src/hooks/useCustomMuiTheme'
import { TemplateEnum } from '@eo-storefronts/eo-core'

const getCategoriesTemplateService = (style: TemplateEnum | null, muiTheme: CustomMuiTheme): StylesCategoriesServiceInterface => {
  switch (style) {
    case TemplateEnum.STYLE_TWO:
      return new StylesCategoriesServiceStyleTwo(muiTheme)
    default:
      return new StylesCategoriesServiceStyleOne(muiTheme)
  }
}

export default getCategoriesTemplateService
