import getCategoriesTemplateService from '~/src/pages/categories/styles/GetCategoriesTemplateService'
import StylesCategoriesServiceInterface from '~/src/pages/categories/styles/StylesCategoriesServiceInterface'
import { useLayout } from '~/src/hooks/layout/useLayout'
import { useCustomMuiTheme } from '~/src/hooks/useCustomMuiTheme'
import useGetPageStyle from '~/src/hooks/layout/useGetPageStyle'

const useGetCategoriesTemplate = (): StylesCategoriesServiceInterface => {
  const muiTheme = useCustomMuiTheme()
  const pageStyle = useGetPageStyle('categories')

  useLayout('categories')

  return getCategoriesTemplateService(pageStyle, muiTheme)
}

export default useGetCategoriesTemplate
