import { Category } from '@eo-storefronts/eo-core'
import CategoryListItem from '~/src/components/categories/category-list/CategoryListItem'
import InPageLoader from '~/src/components/loader/InPageLoader'
import GridList from '~/src/components/mui-wrappers/grid-list/GridList'
import GridListItem from '~/src/components/mui-wrappers/grid-list/GridListItem'
import useGetCategoryListTemplate from '~/src/components/categories/category-list/styles/useGetCategoryListTemplate'

interface Props {
  categories: Category[],
}

const CategoryList = (props: Props) => {
  const styles = useGetCategoryListTemplate()

  if (!props.categories) {
    return <InPageLoader />
  }

  return (
    <GridList sx={styles.getGridListSxProps()}>
      {props.categories.map((category: Category, index: number) => (
        <GridListItem
          key={index}
          {...styles.getGridListItemProps()}
        >
          <CategoryListItem category={category} />
        </GridListItem>
      ))}
    </GridList>
  )
}

export default CategoryList
