import { Category } from '@eo-storefronts/eo-core'
import { Card, CardContent, cardContentClasses } from '@mui/material'
import CardMedia from '~/src/components/mui-wrappers/CardMedia'
import Title from '~/src/components/mui-wrappers/Title'
import { useCustomMuiTheme } from '~/src/hooks/useCustomMuiTheme'
import { useEoValue } from '~/src/hooks/useEoState'
import { LANGUAGE_STATE } from '~/src/stores/lang'
import { LAYOUT_STATE } from '~/src/stores/layout'

interface Props {
  category: Category,
}

const CategoryItem = (props: Props) => {
  const muiTheme = useCustomMuiTheme()
  const { isMobile } = useEoValue(LAYOUT_STATE)
  const lang = useEoValue(LANGUAGE_STATE)

  if (!props.category) {
    return null
  }

  return (
    <Card
      sx={{
        backgroundColor: 'secondary.main',
        color: 'secondary.contrastText',
        cursor: 'pointer',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'space-between',
        transition: 'var(--ease-in-out)',
        '&:hover': {
          transform: 'scale(1.025)'
        }
      }}
      elevation={muiTheme.elevation}
    >
      <CardMedia
        src={props.category?.images[0]?.detail}
        alt={props.category?.name[lang] || ''}
        sx={{
          backgroundColor: 'wells.main',
          color: 'wells.contrastText'
        }}
      />
      <CardContent
        sx={{
          textAlign: 'center',
          flex: '1',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          p: 1,
          [`&.${cardContentClasses.root}:last-child`]: {
            paddingBottom: 1
          }
        }}
      >
        <Title
          variant={isMobile ? 'body1' : 'h5'}
          component='h5'
          className='eo-ellipsis'
          sx={{
            color: 'inherit',
            wordBreak: 'break-word',
            [muiTheme.breakpoints.only('xs')]: {
              fontWeight: 'bold'
            }
          }}
        >
          {props.category?.name[lang]}
        </Title>
      </CardContent>
    </Card>
  )
}

export default CategoryItem
