import { ListItem, ListItemProps, styled } from '@mui/material'

const _offset = 15

const _calculatePosition = (position: number): number => {
  return position % _offset
}

const _getGridColumnEndPosition = (position: number): number => {
  switch (position) {
    case 0:
    case 3:
    case 12:
      return 2
    default:
      return 1
  }
}

const _getGridRowEndPosition = (position: number): number => {
  switch (position) {
    case 2:
    case 5:
    case 6:
    case 9:
    case 10:
    case 13:
      return 2
    default:
      return 1
  }
}

interface Props extends ListItemProps {
  position: number,
}

const BricksItem = styled(({ sx = {}, position, ...otherProps }: Props) => {
  return (
    <ListItem
      sx={{
        ...sx,
        gridColumnEnd: `span ${_getGridRowEndPosition(_calculatePosition(position))}`,
        gridRowEnd: `span ${_getGridColumnEndPosition(_calculatePosition(position))}`
      }}
      {...otherProps} />
  )
})(() => ({ padding: 0 }))

export default BricksItem

