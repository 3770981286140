import { CustomMuiTheme } from '~/src/hooks/useCustomMuiTheme'
import { TemplateEnum } from '@eo-storefronts/eo-core'
import StylesCategoryListServiceInterface
  from '~/src/components/categories/category-list/styles/StylesCategoryListServiceInterface'
import StylesCategoryListServiceStyleTwo
  from '~/src/components/categories/category-list/styles/StylesCategoryListServiceStyleTwo'
import StylesCategoryListServiceStyleOne
  from '~/src/components/categories/category-list/styles/StylesCategoryListServiceStyleOne'

const getCategoryListTemplateService = (style: TemplateEnum | null, muiTheme: CustomMuiTheme): StylesCategoryListServiceInterface => {
  switch (style) {
    case TemplateEnum.STYLE_TWO:
      return new StylesCategoryListServiceStyleTwo(muiTheme)
    default:
      return new StylesCategoryListServiceStyleOne(muiTheme)
  }
}

export default getCategoryListTemplateService
