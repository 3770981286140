import { SxProps } from '@mui/material'
import TemplateServiceStyleBase from '~/src/utils/template/TemplateServiceStyleBase'
import StylesCategoryListServiceInterface
  from '~/src/components/categories/category-list/styles/StylesCategoryListServiceInterface'
import { GridListItemProps } from '~/src/types/GridList'

export default class StylesCategoryListServiceStyleOne extends TemplateServiceStyleBase implements StylesCategoryListServiceInterface {
  public getPageSx(): SxProps {
    return {}
  }

  public getGridListSxProps(): SxProps {
    return {
      p: 0,
      pl: 3,
      pt: 3,
      [this.muiTheme.breakpoints.down('md')]: {
        px: 2
      }
    }
  }

  public getGridListItemProps(): GridListItemProps {
    return {
      xl: 2,
      lg: 3,
      md: 6,
      sm: 4,
      xs: 6
    }
  }
}
