import { MarketplaceCategory } from '@eo-storefronts/eo-core'
import Box from '@mui/material/Box'

interface Props {
  item: MarketplaceCategory,
}

const MarketplaceCategoryItem = ({ item }: Props) => {
  return (
    <Box
      component='span'
      sx={{
        width: 'calc(100% - 1.5rem)',
        position: 'absolute',
        bottom: '1rem',
        left: '1rem',
        textTransform: 'uppercase',
        fontWeight: 'bold',
        fontSize: '0.85rem',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden'
      }}
    >
      {item.name}
    </Box>
  )
}

export default MarketplaceCategoryItem
