import { List, styled } from '@mui/material'

const Bricks = styled(List)(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: 'repeat(2, minmax(auto, 1fr))',
  gridTemplateRows: 'repeat(auto-fit, 200px)',
  gridAutoFlow: 'dense',
  gridGap: theme.spacing(1),
  padding: theme.spacing(1),
  listStyle: 'none',
  '> *': {
    minHeight: '200px'
  },
  [theme.breakpoints.up('md')]: {
    padding: theme.spacing(0),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    gridTemplateColumns: 'repeat(4, minmax(auto, 1fr))'
  },
  [theme.breakpoints.up('lg')]: {
    gridTemplateColumns: 'repeat(6, minmax(auto, 1fr))'
  }
}))

export default Bricks
