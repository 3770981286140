import StylesProductsSpotlightedServiceInterface
  from '~/src/components/products/products-spotlighted/styles/StylesProductsSpotlightedServiceInterface'
import StylesProductsSpotlightedServiceStyleOne
  from '~/src/components/products/products-spotlighted/styles/StylesProductsSpotlightedServiceStyleOne'
import StylesProductsSpotlightedServiceStyleTwo
  from '~/src/components/products/products-spotlighted/styles/StylesProductsSpotlightedServiceStyleTwo'
import { CustomMuiTheme } from '~/src/hooks/useCustomMuiTheme'
import { TemplateEnum, Theme } from '@eo-storefronts/eo-core'
import StylesProductsSpotlightedServiceStyleFour
  from '~/src/components/products/products-spotlighted/styles/StylesProductsSpotlightedServiceStyleFour'

const getProductsSpotlightedTemplateService = (style: TemplateEnum | null | undefined, muiTheme: CustomMuiTheme, firmTheme?: Theme): StylesProductsSpotlightedServiceInterface => {
  switch (style) {
    case TemplateEnum.STYLE_TWO:
      return new StylesProductsSpotlightedServiceStyleTwo(muiTheme, firmTheme)
    case TemplateEnum.STYLE_FOUR:
      return new StylesProductsSpotlightedServiceStyleFour(muiTheme, firmTheme)
    default:
      return new StylesProductsSpotlightedServiceStyleOne(muiTheme, firmTheme)
  }
}

export default getProductsSpotlightedTemplateService
