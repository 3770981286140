import { SxProps } from '@mui/material'
import StylesProductsSpotlightedServiceInterface
  from '~/src/components/products/products-spotlighted/styles/StylesProductsSpotlightedServiceInterface'
import TemplateServiceStyleBase from '~/src/utils/template/TemplateServiceStyleBase'

export default class StylesProductsSpotlightedServiceStyleTwo extends TemplateServiceStyleBase implements StylesProductsSpotlightedServiceInterface {
  public getPageSx(): SxProps {
    return {}
  }

  public getTitleSx(): SxProps {
    return {
      fontWeight: 600,
      pt: 4,
      color: 'background.contrastText',
      [this.muiTheme.breakpoints.down('md')]: {
        pt: 2,
        pl: 2
      }
    }
  }

  public getScrollableListSxProps(): SxProps {
    return {
      scrollBehavior: 'smooth',
      scrollSnapType: 'x mandatory',
      py: 2,
      px: 1,
      [this.muiTheme.breakpoints.up('md')]: {
        scrollPaddingLeft: this.muiTheme.spacing(1),
        width: `calc(100% + ${this.muiTheme.spacing(2)})`,
        ml: -1
      },
      [this.muiTheme.breakpoints.down('md')]: {
        px: 2
      }
    }
  }

  public getScrollableItemSxProps(): SxProps {
    return {
      scrollSnapAlign: 'start',
      flexShrink: '0',
      flexGrow: '0',
      flexBasis: `calc(20% - (${this.muiTheme.spacing(1.5)} + 1px))`,
      [this.muiTheme.breakpoints.down('xl')]: {
        flexBasis: `calc(33.3333% - (${this.muiTheme.spacing(1.5)} - 1px))`
      },
      [this.muiTheme.breakpoints.down('lg')]: {
        flexBasis: '100%'
      },
      [this.muiTheme.breakpoints.down('md')]: {
        flexBasis: `calc(50% - ${this.muiTheme.spacing(1)})`,
        scrollMarginLeft: this.muiTheme.spacing(2)
      }
    }
  }
}
