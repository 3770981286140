import { MarketplaceCategory } from '@eo-storefronts/eo-core'
import MarketplaceCategoryItem from '~/src/components/marketplace/categories/MarketplaceCategoryItem'
import Link from '~/src/components/mui-wrappers/Link'
import Bricks from '~/src/components/mui-wrappers/styled/bricks'
import BricksItem from '~/src/components/mui-wrappers/styled/bricks/BricksItem'
import { useCustomMuiTheme } from '~/src/hooks/useCustomMuiTheme'
import { useEoValue } from '~/src/hooks/useEoState'
import RoutesEnum from '~/src/router/enums/routes.enum'
import { MARKETPLACE_CATEGORIES_STATE } from '~/src/stores/marketplace/categories'
import CssUtils from '~/src/utils/CssUtils'

const MarketplaceCategories = () => {
  const muiTheme = useCustomMuiTheme()
  const categories = useEoValue(MARKETPLACE_CATEGORIES_STATE)

  return (
    <Bricks>
      {categories.map((category: MarketplaceCategory, index) => (
        <BricksItem
          key={index}
          position={index}
          sx={{
            position: 'relative',
            background: `linear-gradient(to top, #575758ba, ${CssUtils.addTransparencyToHexColor(muiTheme.palette.primary.main, 25)}), url(${category.image}) no-repeat top center`,
            color: 'white',
            backgroundSize: 'cover',
            borderRadius: '20px',
            '&:hover': {
              cursor: 'pointer',
              boxShadow: 8
            }
          }}
        >
          <Link
            href={`${RoutesEnum.CATEGORY}/${category.uuid}${RoutesEnum.LOCATIONS}`}
            sx={{
              color: 'inherit',
              height: '100%',
              width: '100%'
            }}
          >
            <MarketplaceCategoryItem item={category} />
          </Link>
        </BricksItem>
      ))}
    </Bricks>
  )
}

export default MarketplaceCategories
