import { Category } from '@eo-storefronts/eo-core'
import CategoryItem from '~/src/components/categories/CategoryItem'
import Link from '~/src/components/mui-wrappers/Link'
import { useCategoryAvailable } from '~/src/hooks/categories/useCategoryAvailable'
import routes from '~/src/router/enums/routes.enum'

interface Props {
  category: Category,
}

const CategoryListItem = ({ category }: Props) => {
  const { available } = useCategoryAvailable(category.id)

  return (
    <Link
      href={`${routes.CATEGORY}/${category.id}${routes.PRODUCTS}`}
      className='eo-h-full'
      removeBaseLinkStyle
      sx={{
        filter: !available ? 'grayscale(1) opacity(.5)' : ''
      }}
    >
      <CategoryItem category={category} />
    </Link>
  )
}

export default CategoryListItem
