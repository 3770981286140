import CategoriesContainer from '~/src/components/categories/categories-container'
import MarketplaceCategories from '~/src/components/marketplace/categories'
import useGetCategoriesTemplate from '~/src/pages/categories/styles/useGetCategoriesTemplate'
import DocumentTitleInjector from '~/src/components/root/DocumentTitleInjector'
import { useEoValue } from '~/src/hooks/useEoState'
import { IS_ON_MARKETPLACE_PAGE_SELECTOR } from '~/src/stores/layout'
import RoutesEnum from '~/src/router/enums/routes.enum'
import SlideFromRight from '~/src/components/mui-wrappers/animations/motion/SlideFromRight'
import { Constants } from '~/src/helpers/constants'

const Categories = () => {
  const isOnMarketplacePage = useEoValue(IS_ON_MARKETPLACE_PAGE_SELECTOR)
  const pageName: keyof typeof RoutesEnum = isOnMarketplacePage ? 'CATEGORIES' : 'MENU'

  useGetCategoriesTemplate()

  return (
    <SlideFromRight
      pathname={pageName}
      transition={{
        duration: .5,
        delay: Constants.MOTION_ANIMATION_DELAY
      }}
    >
      <DocumentTitleInjector pageName={pageName} />
      {isOnMarketplacePage ? <MarketplaceCategories /> : <CategoriesContainer />}
    </SlideFromRight>
  )
}

export default Categories
